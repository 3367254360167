import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'whatwg-fetch';
import 'classlist.js';

if (!window.location.origin) {
	const { protocol, hostname, port } = window.location;
	window.location.origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
}

// Create Element.remove() function if not exist
if (!('remove' in Element.prototype)) {
	Element.prototype.remove = function () {
		if (this.parentNode) {
			this.parentNode.removeChild(this);
		}
	};
}

window.addEventListener(
	'dragover',
	function (e) {
		e.preventDefault();
		e.stopPropagation();
		return false;
	},
	false,
);

window.addEventListener(
	'drop',
	function (e) {
		e.preventDefault();
		e.stopPropagation();
		return false;
	},
	false,
);

(function () {
	if (typeof window.CustomEvent === 'function') return false;

	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: undefined };
		const evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;
	window.CustomEvent = CustomEvent;
})();
