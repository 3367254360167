import './polyfill';

import config from 'config';
import createAuth0Client from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/browser';
import { Dedupe } from '@sentry/integrations';

// add global constants for version and build
global.VERSION = process.env.npm_package_version;
global.BUILD_NUMBER = process.env.BUILD_NUMBER || 0;

// setup sentry if enabled
if (config.sentryEnabled === 'true') {
	Sentry.init({
		dsn: config.sentryDSN,
		release: `cms@${global.VERSION}`,
		attachStackTrace: true,
		environment: config.name,
		integrations: [new Dedupe()],
	});
}

/** Will be populated with an Auth0Client on page load. */
let auth0;

/**
 * Triggers a logout through Auth0.
 * @returns A promise which resolves with undefined once logged out.
 */
export async function logout() {
	return await auth0.logout({
		returnTo: window.location.origin,
	});
}

/**
 * Retrieve the current tokens from Auth0 and triggers a refresh if needed.
 * @returns A promise that resolves to the result of Auth0Client#getTokenSilently({ detailedResponse: true }).
 */
export async function refreshToken() {
	try {
		return await auth0.getTokenSilently({ detailedResponse: true });
	} catch (err) {
		// if the error encountered is recoverable, try explicitly signing in
		if (err.error === 'login_required' || err.error === 'consent_required') {
			await doLoginWithPopup();
			return await refreshToken();
		}

		throw err;
	}
}

/**
 * Retrieves the bearer token for the current user to be used for API calls.
 * Note: This will also refresh the tokens if needed.
 * @returns A promise that resolves to a string representing a bearer token.
 */
export async function getBearerToken() {
	const { access_token } = await refreshToken();

	if (access_token && access_token.length) {
		return `Bearer ${access_token}`;
	}

	return undefined;
}

function getAuth0Connection() {
	let auth0Connection = null;

	//grab sudomain to check if we need to do sso
	const subdomain = window.location.origin.match(/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*)/i);
	if (subdomain && subdomain.length == 2) {
		//should be exactly 2 elements in the regex matched results
		//check if we are doing sso
		if (subdomain[1].indexOf('sso-') != -1) {
			auth0Connection = subdomain[1].substring(subdomain[1].indexOf('sso-') + 4);
		}
	}

	return auth0Connection;
}

async function doLoginWithPopup() {
	// dynamically import the token refresh to prevent a large entry bundle size and allow access to i18n
	const { default: triggerPopup } = await import('core/TokenRefresh');
	return triggerPopup(() => auth0.loginWithPopup());
}

async function doLoginWithRedirect() {
	const returnTo = window.location.href.replace(window.location.origin, '');

	await auth0.loginWithRedirect({
		redirect_uri: window.location.origin,
		appState: { returnTo },
	});
}

async function continueToApp() {
	// pre-populate the auth0 token cache
	await refreshToken();

	const main = await import('main');
	main.default();
}

function fail(message) {
	document.getElementById('app').innerHTML = `
		<div style='
			position: absolute;
			top: 50%;
			left: 50%;
			height: 150px;
			color: red;
			text-align: center;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);'
		>
			${message}
		</div>
	`;

	throw message;
}

window.addEventListener('load', async () => {
	// use a regex to extract the naked domain and subdomain from the auth0 host string
	// if auth0 = "https://sso-cms.mappedin.com/en/dashboard", then matches[1] = "sso-cms.mappedin.com"
	const matches = config.hosts.auth0.match(/^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i);
	const auth0Domain = matches && matches[1];

	// create a new auth0 client with the given config, will trigger a call to checkSession()

	const auth0Options = {
		domain: auth0Domain,
		client_id: config.auth0ClientId,
		audience: config.auth0Aud,
		useRefreshTokens: true,
		cacheLocation: 'localstorage',
	};
	const connection = getAuth0Connection();
	if (connection) {
		auth0Options.connection = connection;
	}
	auth0 = await createAuth0Client(auth0Options);

	const isAuthenticated = await auth0.isAuthenticated();

	// continue straight to app if user is already authenticated
	if (isAuthenticated) {
		return await continueToApp();
	}

	// check if a auth callback has occurred
	const { search } = window.location;
	if (
		search.includes('code=') ||
		search.includes('state=') ||
		search.includes('error=') ||
		search.includes('error_description=')
	) {
		let returnTo = '/';

		try {
			const { appState = {} } = await auth0.handleRedirectCallback();

			// if a specific returnTo path is found in appState, use it instead of '/'
			returnTo = appState.returnTo || returnTo;
		} catch (err) {
			return fail(err);
		}

		window.history.replaceState({}, document.title || '', returnTo);
		return await continueToApp();
	} else {
		// no login present, no callback occurred, user must login
		await doLoginWithRedirect();
	}
});
